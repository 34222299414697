import React, { Component } from 'react';
import './FormContact.css';
import {
    InputGroup,
    InputGroupAddon,
    Button,
    Input,
    FormGroup, 
    Form,
    FormText, 
    Label
} from 'reactstrap';

import fbse from '../../fireBaseCloud';
import Translate from './../../utils/Translate';


class FormContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: [],
            email: '',
            name: '',
            phone: '',
            description: '',
            hidden: false,
            ok: false
        };
    }

    resetForm() {
      this.setState({email: '', name: '', phone: ''})
    }

    onChange(field, value) {
      this.setState({[field]: value})
    }

    sendMessage(e) {
        e.preventDefault();
        const { 
            email, 
            name, 
            phone, 
            description,
            developmentType, 
            developmentTime, 
            projectStatus, 
            businessSize,
            countryCode
        } = this.state;

        const fullPhoneNumber = countryCode + phone;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timeZoneToCountry = {
            "America/New_York": "US",
            "America/Chicago": "US",
            "America/Los_Angeles": "US",
            "America/Denver": "US",
            "America/Phoenix": "US",
            "America/Anchorage": "US",
            "America/Honolulu": "US",
            "America/Detroit": "US",
            "America/Indianapolis": "US",
            "America/Toronto": "CA",
            "America/Vancouver": "CA",
            "America/Montreal": "CA",
            "America/Winnipeg": "CA",
            "America/Halifax": "CA",
            "America/St_Johns": "CA",
            "America/Mexico_City": "MX",
            "America/Monterrey": "MX",
            "America/Cancun": "MX",
            "America/Guatemala": "GT",
            "America/El_Salvador": "SV",
            "America/Managua": "NI",
            "America/Panama": "PA",
        
            // South America
            "America/Bogota": "CO",
            "America/Lima": "PE",
            "America/Quito": "EC",
            "America/Sao_Paulo": "BR",
            "America/Rio_Branco": "BR",
            "America/Argentina/Buenos_Aires": "AR",
            "America/La_Paz": "BO",
            "America/Santiago": "CL",
            "America/Caracas": "VE",
            "America/Asuncion": "PY",
            "America/Montevideo": "UY",
        
            // Europe
            "Europe/London": "GB",
            "Europe/Dublin": "IE",
            "Europe/Paris": "FR",
            "Europe/Madrid": "ES",
            "Europe/Barcelona": "ES",
            "Europe/Berlin": "DE",
            "Europe/Frankfurt": "DE",
            "Europe/Rome": "IT",
            "Europe/Milan": "IT",
            "Europe/Amsterdam": "NL",
            "Europe/Brussels": "BE",
            "Europe/Stockholm": "SE",
            "Europe/Oslo": "NO",
            "Europe/Copenhagen": "DK",
            "Europe/Helsinki": "FI",
            "Europe/Vienna": "AT",
            "Europe/Warsaw": "PL",
            "Europe/Prague": "CZ",
            "Europe/Budapest": "HU",
            "Europe/Bucharest": "RO",
            "Europe/Athens": "GR",
            "Europe/Istanbul": "TR",
            "Europe/Moscow": "RU",
            "Europe/Kiev": "UA",
            "Europe/Lisbon": "PT",
        
            // Middle East & Africa
            "Asia/Dubai": "AE",
            "Asia/Riyadh": "SA",
            "Asia/Tehran": "IR",
            "Asia/Jerusalem": "IL",
            "Asia/Baghdad": "IQ",
            "Asia/Kuwait": "KW",
            "Asia/Qatar": "QA",
            "Asia/Bahrain": "BH",
            "Asia/Muscat": "OM",
            "Africa/Cairo": "EG",
            "Africa/Johannesburg": "ZA",
            "Africa/Lagos": "NG",
            "Africa/Nairobi": "KE",
            "Africa/Casablanca": "MA",
        
            // Asia
            "Asia/Tokyo": "JP",
            "Asia/Seoul": "KR",
            "Asia/Shanghai": "CN",
            "Asia/Beijing": "CN",
            "Asia/Singapore": "SG",
            "Asia/Hong_Kong": "HK",
            "Asia/Bangkok": "TH",
            "Asia/Jakarta": "ID",
            "Asia/Kuala_Lumpur": "MY",
            "Asia/Manila": "PH",
            "Asia/Taipei": "TW",
            "Asia/Kolkata": "IN",
            "Asia/Karachi": "PK",
            "Asia/Dhaka": "BD",
            "Asia/Yangon": "MM",
            "Asia/Hanoi": "VN",
        
            // Australia & Pacific
            "Australia/Sydney": "AU",
            "Australia/Melbourne": "AU",
            "Australia/Brisbane": "AU",
            "Australia/Perth": "AU",
            "Australia/Adelaide": "AU",
            "Pacific/Auckland": "NZ",
            "Pacific/Fiji": "FJ",
            "Pacific/Honolulu": "US",
            "Pacific/Port_Moresby": "PG",
            "Pacific/Tahiti": "PF"
        };
    
        const userCountry = timeZoneToCountry[timeZone] || "Unknown"; // Default if not found

        const params = {
            email: email,
            name: name,
            phone: fullPhoneNumber,
            country: userCountry,
            description: description,
            company_id: 1,
            url: window.location.href,
            origin: "landing",
            date: Date(),
            developmentType: developmentType,
            developmentTime: developmentTime,
            projectStatus: projectStatus,
            businessSize: businessSize,
            countryCode: countryCode
        };

        if (email && developmentType && developmentTime && projectStatus && businessSize && countryCode) {
            
            fbse.database().ref('/form').push(params).then(() => {  })
            .catch((e) => {})
            .finally(() => {
                const url = 'https://massivex.addabra.com/api/v1/customer';
                fetch(url, {
                method: 'POST',
                body: JSON.stringify({customer: params}),
                headers:{
                    'Content-Type': 'application/json'
                }
                }).then(res => console.log(res))
                .catch(error => console.error('Error:', error))
                .then(response => console.log('Success:', response));

                this.resetForm();
                this.setState({ok: true, hidden: true});
                
                window.gtag('event', 'conversion', {email: email});
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-586592317/vW1lCLTRld8BEL3g2pcC'
                });

                window.gtag('event', 'conversion', {email: email});
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-586592317/J8BdCNS9v5sYEL3g2pcC'
                });  

                window.location = "/thank-you"
                setTimeout(() => {
                    this.setState({ ok: false });
                }, 20000)
            });
        } else {
            alert('Por favor llena los campos requeridos');
        };
    }

    render() {

        const {email, name, phone, description, ok, hidden, countryCode} = this.state;
        return (
            <div id="contacto" className="form-container">
                {this.state.alert && <div className={`alert alert-${this.state.alertData.type}`} role='alert' >
                        <div className='container'>
                            {this.state.alertData.message}
                        </div>
                </div>}
                {!hidden && <div className='container'>
                        <form ref='contactForm'>
                        <div className="sectionFormContact" >
                            <h3 className="formTitle">
                                {Translate.get('doYouHaveAProject', "¿Tienes un proyecto en mente ó necesitas una cotización?")} <br/> 
                                {Translate.get('leaveYourContact', "")} <br/> 
                                {Translate.get('consultantCall', "")} 
                            </h3>
                            <Form>
                                <FormGroup>
                                <Input id='name' className="inputContact" placeholder={Translate.get('nameFirstName', '')}  type="text" onChange={e => this.onChange('name', e.target.value)} value={name} />
                                </FormGroup>

                                <FormGroup>
                                <Input id='email' className="inputContact" placeholder={Translate.get('exampleEmail', '')} type="email" onChange={e => this.onChange('email', e.target.value)} value={email} />
                                </FormGroup>

                                <FormGroup className="form-group-div">
                                    <div className="phone-input-group">
                                            <Input 
                                                type="select" 
                                                className="inputContact countryCodeSelect"
                                                placeholder={Translate.get('countryCode', '')}
                                                onChange={e => this.onChange('countryCode', e.target.value)}
                                                value={countryCode}
                                                style={{ width: "30%", minWidth: "100px", maxWidth: "169px" }}
                                            >
                                                <option value="">{Translate.get('countryCode', '')}</option>
                                                <option value="1">🇺🇸 +1 (USA)</option>
                                                <option value="52">🇲🇽 +52 (Mexico)</option>
                                                <option value="971">🇦🇪 +971 (UAE)</option>
                                                <option value="1">🇨🇦 +1 (Canada)</option>
                                                <option value="57">🇨🇴 +57 (Colombia)</option>
                                                <option value="44">🇬🇧 +44 (UK)</option>
                                                <option value="93">🇦🇫 +93 (Afghanistan)</option>
                                                <option value="355">🇦🇱 +355 (Albania)</option>
                                                <option value="213">🇩🇿 +213 (Algeria)</option>
                                                <option value="376">🇦🇩 +376 (Andorra)</option>
                                                <option value="244">🇦🇴 +244 (Angola)</option>
                                                <option value="54">🇦🇷 +54 (Argentina)</option>
                                                <option value="374">🇦🇲 +374 (Armenia)</option>
                                                <option value="61">🇦🇺 +61 (Australia)</option>
                                                <option value="43">🇦🇹 +43 (Austria)</option>
                                                <option value="994">🇦🇿 +994 (Azerbaijan)</option>
                                                <option value="973">🇧🇭 +973 (Bahrain)</option>
                                                <option value="880">🇧🇩 +880 (Bangladesh)</option>
                                                <option value="375">🇧🇾 +375 (Belarus)</option>
                                                <option value="32">🇧🇪 +32 (Belgium)</option>
                                                <option value="501">🇧🇿 +501 (Belize)</option>
                                                <option value="229">🇧🇯 +229 (Benin)</option>
                                                <option value="591">🇧🇴 +591 (Bolivia)</option>
                                                <option value="387">🇧🇦 +387 (Bosnia and Herzegovina)</option>
                                                <option value="267">🇧🇼 +267 (Botswana)</option>
                                                <option value="55">🇧🇷 +55 (Brazil)</option>
                                                <option value="359">🇧🇬 +359 (Bulgaria)</option>
                                                <option value="226">🇧🇫 +226 (Burkina Faso)</option>
                                                <option value="257">🇧🇮 +257 (Burundi)</option>
                                                <option value="855">🇰🇭 +855 (Cambodia)</option>
                                                <option value="237">🇨🇲 +237 (Cameroon)</option>
                                                <option value="56">🇨🇱 +56 (Chile)</option>
                                                <option value="86">🇨🇳 +86 (China)</option>
                                                <option value="57">🇨🇴 +57 (Colombia)</option>
                                                <option value="506">🇨🇷 +506 (Costa Rica)</option>
                                                <option value="385">🇭🇷 +385 (Croatia)</option>
                                                <option value="53">🇨🇺 +53 (Cuba)</option>
                                                <option value="357">🇨🇾 +357 (Cyprus)</option>
                                                <option value="420">🇨🇿 +420 (Czech Republic)</option>
                                                <option value="45">🇩🇰 +45 (Denmark)</option>
                                                <option value="253">🇩🇯 +253 (Djibouti)</option>
                                                <option value="593">🇪🇨 +593 (Ecuador)</option>
                                                <option value="20">🇪🇬 +20 (Egypt)</option>
                                                <option value="503">🇸🇻 +503 (El Salvador)</option>
                                                <option value="372">🇪🇪 +372 (Estonia)</option>
                                                <option value="251">🇪🇹 +251 (Ethiopia)</option>
                                                <option value="358">🇫🇮 +358 (Finland)</option>
                                                <option value="33">🇫🇷 +33 (France)</option>
                                                <option value="995">🇬🇪 +995 (Georgia)</option>
                                                <option value="49">🇩🇪 +49 (Germany)</option>
                                                <option value="30">🇬🇷 +30 (Greece)</option>
                                                <option value="502">🇬🇹 +502 (Guatemala)</option>
                                                <option value="224">🇬🇳 +224 (Guinea)</option>
                                                <option value="509">🇭🇹 +509 (Haiti)</option>
                                                <option value="504">🇭🇳 +504 (Honduras)</option>
                                                <option value="36">🇭🇺 +36 (Hungary)</option>
                                                <option value="354">🇮🇸 +354 (Iceland)</option>
                                                <option value="91">🇮🇳 +91 (India)</option>
                                                <option value="62">🇮🇩 +62 (Indonesia)</option>
                                                <option value="98">🇮🇷 +98 (Iran)</option>
                                                <option value="353">🇮🇪 +353 (Ireland)</option>
                                                <option value="972">🇮🇱 +972 (Israel)</option>
                                                <option value="39">🇮🇹 +39 (Italy)</option>
                                                <option value="81">🇯🇵 +81 (Japan)</option>
                                                <option value="962">🇯🇴 +962 (Jordan)</option>
                                                <option value="254">🇰🇪 +254 (Kenya)</option>
                                                <option value="82">🇰🇷 +82 (South Korea)</option>
                                                <option value="965">🇰🇼 +965 (Kuwait)</option>
                                                <option value="371">🇱🇻 +371 (Latvia)</option>
                                                <option value="961">🇱🇧 +961 (Lebanon)</option>
                                                <option value="218">🇱🇾 +218 (Libya)</option>
                                                <option value="352">🇱🇺 +352 (Luxembourg)</option>
                                                <option value="60">🇲🇾 +60 (Malaysia)</option>
                                                <option value="960">🇲🇻 +960 (Maldives)</option>
                                                <option value="223">🇲🇱 +223 (Mali)</option>
                                                <option value="356">🇲🇹 +356 (Malta)</option>
                                                <option value="230">🇲🇺 +230 (Mauritius)</option>
                                                <option value="52">🇲🇽 +52 (Mexico)</option>
                                                <option value="373">🇲🇩 +373 (Moldova)</option>
                                                <option value="976">🇲🇳 +976 (Mongolia)</option>
                                                <option value="212">🇲🇦 +212 (Morocco)</option>
                                                <option value="95">🇲🇲 +95 (Myanmar)</option>
                                                <option value="977">🇳🇵 +977 (Nepal)</option>
                                                <option value="31">🇳🇱 +31 (Netherlands)</option>
                                                <option value="64">🇳🇿 +64 (New Zealand)</option>
                                                <option value="234">🇳🇬 +234 (Nigeria)</option>
                                                <option value="47">🇳🇴 +47 (Norway)</option>
                                                <option value="968">🇴🇲 +968 (Oman)</option>
                                                <option value="92">🇵🇰 +92 (Pakistan)</option>
                                                <option value="507">🇵🇦 +507 (Panama)</option>
                                                <option value="595">🇵🇾 +595 (Paraguay)</option>
                                                <option value="51">🇵🇪 +51 (Peru)</option>
                                                <option value="63">🇵🇭 +63 (Philippines)</option>
                                                <option value="48">🇵🇱 +48 (Poland)</option>
                                                <option value="351">🇵🇹 +351 (Portugal)</option>
                                                <option value="974">🇶🇦 +974 (Qatar)</option>
                                                <option value="40">🇷🇴 +40 (Romania)</option>
                                                <option value="7">🇷🇺 +7 (Russia)</option>
                                                <option value="966">🇸🇦 +966 (Saudi Arabia)</option>
                                                <option value="65">🇸🇬 +65 (Singapore)</option>
                                                <option value="27">🇿🇦 +27 (South Africa)</option>
                                                <option value="34">🇪🇸 +34 (Spain)</option>
                                                <option value="94">🇱🇰 +94 (Sri Lanka)</option>
                                                <option value="46">🇸🇪 +46 (Sweden)</option>
                                                <option value="41">🇨🇭 +41 (Switzerland)</option>
                                                <option value="886">🇹🇼 +886 (Taiwan)</option>
                                                <option value="90">🇹🇷 +90 (Turkey)</option>
                                                <option value="380">🇺🇦 +380 (Ukraine)</option>
                                                <option value="998">🇺🇿 +998 (Uzbekistan)</option>
                                                <option value="58">🇻🇪 +58 (Venezuela)</option>
                                                <option value="84">🇻🇳 +84 (Vietnam)</option>
                                            </Input>
                                            <Input 
                                                id="phone" 
                                                className="inputContact phoneInput"
                                                placeholder={Translate.get('mobile', '')} 
                                                type="tel"  
                                                pattern="[0-9]*"
                                                inputMode="numeric"
                                                onChange={e => {
                                                    const value = e.target.value.replace(/\D/g, "");
                                                    this.onChange("phone", value);
                                                }} 
                                                value={phone} 
                                            />
                                        </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="developmentType">{Translate.get('whatTypeDevelopment', '')}</Label>
                                    <Input type="select" className="inputContact" name="developmentType" onChange={e => this.onChange('developmentType', e.target.value)} required>
                                        <option></option>
                                        <option>{Translate.get('AIAgent', '')}</option>
                                        <option>{Translate.get('BAutomation', '')}</option>
                                        <option>{Translate.get('apps', '')}</option>
                                        <option>{Translate.get('webAndApps', '')}</option>
                                        <option>{Translate.get('saasDevelopment', '')}</option>
                                        <option>{Translate.get('webPage', '')}</option>
                                        <option>{Translate.get('uxui', '')}</option>
                                        <option>{Translate.get('enhacement', '')}</option>
                                        <option>{Translate.get('consultancy', '')}</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="projectStatus">{Translate.get('projectStatus', '')}</Label>
                                    <Input type="select" className="inputContact" name="projectStatus" onChange={e => this.onChange('projectStatus', e.target.value)} required>
                                        <option></option>
                                        <option>{Translate.get('iHaveIdea', '')}</option>
                                        <option>{Translate.get('iHaveMVP', '')}</option>
                                        <option>{Translate.get('isWorking', '')}</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                <Label for="businessSize">{Translate.get('howBigIsYourBusiness', '')}</Label>
                                <Input type="select" className="inputContact" name="businessSize" onChange={e => this.onChange('businessSize', e.target.value)} required>
                                        <option></option>
                                        <option>{Translate.get('sizeBusiness', '')}</option>
                                        <option>{Translate.get('startup', '')}</option>
                                        <option>{Translate.get('llc', '')}</option>
                                        <option>{Translate.get('corporation', '')}</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                    <Label for="developmentTime">{Translate.get('timeDoYouNeed', '')}</Label>
                                    <Input type="select" className="inputContact" name="developmentTime" onChange={e => this.onChange('developmentTime', e.target.value)} required>
                                        <option></option>
                                        <option>{Translate.get('days', '')}</option>
                                        <option>{Translate.get('weeks', '')}</option>
                                        <option>{Translate.get('months', '')}</option>
                                    </Input>
                                </FormGroup>

                                <FormGroup>
                                <Label for="description">{Translate.get('describeProject', '')}</Label>
                                    <Input id='text' type="textarea" className="inputContact" placeholder="" onChange={e => this.onChange('description', e.target.value)} value={description} />
                                </FormGroup>

                                <FormGroup>
                                    <Button color="primary-purpple" onClick={this.sendMessage.bind(this)}>{Translate.get('sent', '')}</Button>
                                </FormGroup>
                            </Form>
                            
                        </div>
                    </form>
                    </div>}
                <div>
        
                {/* <div className="calendly-inline-widget" data-url="https://calendly.com/addabra/30min" style={{minWidth:30, height:630}}></div> */}

                </div>
                {ok && <div>
                    <h3 className="formTitle" style={{fontSize: 40, marginTop: 120, marginBottom: 120}}>¡Gracias! 🙌 En breve te contactaremos. 😊 </h3>
                
                </div>}
            </div>
        );
    }
}

export default FormContact;